import React from 'react'
import Icon from 'react-icons-kit';
import { home } from 'react-icons-kit/icomoon/home'
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share"
import HyperlinkShareIcon from "../../../images/svg/icons/hyperlink.svg"
import { instagram } from 'react-icons-kit/fa/instagram';
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
function IconInline(props) {
  const { color, type, round, size } = props

  switch (type) {
    case 'bolt':
      return <BoltTwoToneIcon
        fontSize='large'
        sx={{
          color: color,
          width: size
        }}/>

    case 'facebook':
      return <FacebookIcon
        iconFillColor="#FFF"
        round={round}
        size={40} />

    case 'email':
      return <HyperlinkShareIcon />

    case 'hyperlink':
      return <HyperlinkShareIcon />

    case 'instagram':
      return <div className='instagram' style={{
        aspectRatio: 1,
        background: color ? color: '#5851D8',
        borderRadius: round ? '50%' : '0',
        padding: `0.3rem`
      }}>
        <Icon
          icon={instagram}
          title='Click to save to your list'
          style={{ color: '#FFFFFF', marginTop: '-2px' }}
          size={26} />
        </div>

    case 'linkedin':
      return <LinkedinIcon
        iconFillColor="#FFF"
        round={round}
        size={40} />

    case 'twitter':
      return <TwitterIcon
        iconFillColor="#FFF"
        round={round}
        size={40} />

    default:
      return <Icon icon={home}/>
  }
}

IconInline.defaultProps = {
  round: true,
  color: null,
  size: 40
}

export default IconInline
