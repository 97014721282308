import React from 'react'

import { MdCancel } from '@react-icons/all-files/md/MdCancel'

import useVibemapSearch from '../utils/search-context/hook'

import './styles/FilterChip.scss'

function FilterChip({ id, type, text }) {
  const {
    actions: { setSearchVibes, setSearchCities, setSearchCategory },
  } = useVibemapSearch()

  const removeFilter = () => {
    switch (type) {
      case 'city':
        setSearchCities((previousCities) => {
          return previousCities.filter((city) => city.id != id)
        })
        break;
      case 'vibe':
        setSearchVibes((previousVibes) => {
          const slugs = previousVibes.split(',')
          const filteredSlugs = slugs.filter((slug) => slug !== id)
          return filteredSlugs.join(',')
        })
        break;
      case 'category':
        setSearchCategory('all')
        break;
    }
  }

  return (
    <div className='filter-chip'>
      <span className='filter-chip__label'>{text}</span>
      <button className='filter-chip__remover' onClick={removeFilter} title='Remove filter'>
      <MdCancel size={16} />
      </button>
    </div>
  )
}

export default FilterChip