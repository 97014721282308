import React from 'react'
import useVibemapSearch from "../utils/search-context/hook"

import { debounce } from 'throttle-debounce'

const updateURLParams = debounce(2000, (params) => {
  const searchParams = new URLSearchParams(params).toString()
  window.location.hash = searchParams
})

function VibemapSearchParameterPersister() {
  const { actions, filters, searchTerm, shouldUpdateURL } = useVibemapSearch()

  const {
    setSearchVibes,
    setSearchCategory,
    setSearchTerm
  } = actions

  const {
    vibes: filterVibes,
    category: filterCategory,
  } = filters

  const trimmedSearchTerm = searchTerm?.trim()

  // store search params when they change
  React.useEffect(() => {
    const params = {}

    if (filterVibes && filterVibes !== 'undefined') {
      params.vibes = filterVibes;
    }

    if (filterCategory && filterCategory !== 'undefined') {
      params.category = filterCategory;
    }

    if (!!trimmedSearchTerm) {
      params.term = trimmedSearchTerm;
    }

    if (shouldUpdateURL) {
      updateURLParams(params)
    }

  }, [filterVibes, filterCategory, searchTerm])

  // load search params from URL bar, if any
  React.useEffect(() => {
    try {
      if (window.location.hash) {
        // parse params, excluding hash character
        const params = new URLSearchParams(window.location.hash.slice(1))
        const { vibes, category, term } = Object.fromEntries(params.entries())
        setSearchVibes(vibes)
        setSearchCategory(category)
        setSearchTerm(term)
      }
    } catch (_) {
      // invalid search params
    }
  }, [setSearchCategory, setSearchTerm, setSearchVibes])

  return null
}

export default VibemapSearchParameterPersister
