import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useTemplateContext } from 'components/post/TemplateProvider'

import useVibemapSearch from '../utils/search-context/hook'
import Fuse from 'fuse.js'

//import cities from 'vibemap-constants/constants/cities'
// Uncomment once activityCategories.json is nested
import {
  flattenCategories,
  getVibes,
  getVibeInfo,
  searchCategories,
  searchVibes
} from 'vibemap-constants/dist/vibes.js'
import { sortByPopularity } from 'vibemap-constants/dist/helpers'
import { sortLocations } from 'vibemap-constants/dist/map'
const allVibes = getVibes('all')

const SliderPicker = lazy(() => import('./SliderPicker'))
import './styles/FilterPicker.scss'

function VibemapSearchFilterPicker({
  // Props
  buttonSize,
  doFilterSearch,
  showCities,
  showCategories,
  showVibes,
  suggestedVibes = null,
  threshold = 0.3,
  vibeLabel,
  vibeOrder
}) {
  const {
    actions,
    cities,
    filters,
    searchTerm,
    secondarySearch
} = useVibemapSearch()
  const { setSearchCities, setSearchCategory, setSearchVibes, setSearchCenter } = actions

  const [pickedPointOption, setPickedPointOption] = React.useState( filters.cities?.[0]?.value || 'anywhere')
  const [userCoordinates, setUserCoordinates] = React.useState()
  const [filteredVibes, setFilteredVibes] = React.useState([])

  const templateContext = useTemplateContext()
  const {
    activityCategories,
    previousPage,
  } = templateContext

  // Set vibe options
  const vibes = suggestedVibes
    ? [ ...new Set(suggestedVibes.map(vibe => getVibeInfo(vibe)).concat(allVibes))]
    : vibeOrder == 'popularity'
      ? allVibes.sort(sortByPopularity)
      : allVibes // Alpha or list order

  const sortedActivities = activityCategories
    ?  flattenCategories(activityCategories, 2)
      .sort(sortByPopularity)
    : []

  const activities = sortedActivities

  const pointOptions = React.useMemo(() => {
    const defaultOptions = [
      {
        value: 'anywhere',
        label: 'All Cities',
      },
    ]

    const citiesAsLocations = cities.map((city) => {
      return {
        id: city.id,
        title: city.name,
        location: city.location,
        centerpoint: [city.location.longitude, city.location.latitude],
      }
    })

    const sortedCities = userCoordinates
      ? sortLocations(citiesAsLocations, userCoordinates)
      : citiesAsLocations;

    if (userCoordinates) {
      defaultOptions.push({
        value: 'near-me',
        label: 'Near Me',
        centerpoint: userCoordinates ?? [userCoordinates.longitude, userCoordinates.latitude],
        id: sortedCities[0]?.slug,
      })
    }

    const sortedCityOptions = sortedCities.map((city) => ({
      id: city.id,
      label: `${city.title}${city?.state ? ', ' + city.state : ''}`,
      value: city.title,
      centerpoint: city.centerpoint,
    }))

    return [
      ...defaultOptions,
      ...sortedCityOptions
    ]
  }, [userCoordinates, cities])

  // Get user's location, if it's not already present
  React.useEffect(() => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setUserCoordinates(coords)
        }
      ), (err) => { console.log('Problem with location ', err)}
    }
  }, [])

  React.useEffect(() => {
    if (filters.cities.length > 0) {
      const pickedOption = pointOptions.find((option) =>
        option.id === filters.cities[0].id
        && option.value === filters.cities[0].value
      )

      if (!pickedOption) return
      setPickedPointOption(pickedOption.value)
    } else {
      setPickedPointOption('anywhere')
    }
  }, [filters.cities])

  React.useEffect(() => {
    const pickedOption = pointOptions.find(
      (option) => option.value === pickedPointOption
    )

    if (pickedOption?.centerpoint) {
      setSearchCenter(
        Array.isArray(pickedOption.centerpoint)
          ? pickedOption.centerpoint.join(',')
          : `${pickedOption.centerpoint.longitude},${pickedOption.centerpoint.latitude}`
      )
      setSearchCities([pickedOption])
    } else {
      setSearchCities([])
      setSearchCenter()
    }
  }, [pickedPointOption, setSearchCenter])


  const isVibePicked = React.useCallback((vibeSlug) => {
    if (vibeSlug === 'all') {
      return !filters.vibes;
    }

    return (filters.vibes || "").split(',').includes(vibeSlug)
  }, [filters.vibes])

  const isActivityPicked = React.useCallback((activitySlug) => {
    if (activitySlug === 'all') {
      return !filters.category || filters.category === activitySlug
    }

    return filters.category === activitySlug
  }, [filters.category])

  const isPointPicked = React.useCallback((pointValue) => {
    return pickedPointOption === pointValue
  }, [pickedPointOption])

  const handleVibePick = React.useCallback((vibe) => {
    if (vibe.slug === 'all') {
      setSearchVibes('')
      return;
    }

    if (isVibePicked(vibe.slug)) {
      setSearchVibes((previouslyPickedVibes) => {
        const pickedVibeSlugs = (previouslyPickedVibes || "").split(',')
        const newVibeSlugs = pickedVibeSlugs.filter((slug) => slug !== vibe.slug)
        return newVibeSlugs.join(',')
      })
    } else {
      setSearchVibes((previouslyPickedVibes) => {
        if (previouslyPickedVibes) {
          const pickedVibeSlugs = previouslyPickedVibes.split(',')
          const newVibeSlugs = [...pickedVibeSlugs, vibe.slug]
          return newVibeSlugs.join(',')
        }
        return vibe.slug
      })
    }
  }, [isVibePicked])

  const handleActivityPick = React.useCallback((activity) => {
    setSearchCategory(activity.slug)
  }, [])

  const handlePointPick = React.useCallback((pointOption) => {
    setPickedPointOption(pointOption.value)
  }, [])

  React.useEffect(() => {
    const searchValue = secondarySearch && secondarySearch.field == 'vibes'
      ? secondarySearch.value
      : searchTerm

    if (searchValue && doFilterSearch && vibes) {
      const searchResult = searchVibes(searchValue)
      const resultItems = searchResult.map(({ item }) => item);
      setFilteredVibes(resultItems)
    } else {
      setFilteredVibes([
        { name: 'All', key: 'all', slug: 'all', },
        ...vibes
      ])
    }
  }, [doFilterSearch, searchTerm, secondarySearch])

  const filteredActivities = React.useMemo(() => {
    if (!searchTerm || !doFilterSearch) return activities;

    const categoryResults = searchCategories(searchTerm)

    return categoryResults.map(({ item }) => item);
  }, [doFilterSearch, searchTerm])

  const filteredPointOptions = React.useMemo(() => {
    if (!searchTerm || !doFilterSearch) return pointOptions;

    const fuse = new Fuse(pointOptions || [], {
      distance: 3,
      keys: ['value'],
      threshold: threshold,
    });

    const results = fuse.search(searchTerm);
    return results.map(({ item }) => item);
  }, [doFilterSearch, searchTerm, cities])

  return (
    <>
      {showCities &&
        <div className='filter'>
          <label className='filter__label'>Location</label>
          <Suspense fallback={<div>Loading</div>}>
            <SliderPicker
              extractItemKey={({ label }) => label}
              items={filteredPointOptions}
              onPick={handlePointPick}
              renderItem={(point) => (
                <button
                  className={`button secondary filter__option ${buttonSize} ${isPointPicked(point.value) ? 'filter__option--picked' : ''}`}
                  type='button'
                >
                  {point.label}
                </button>
              )}
            />
          </Suspense>
        </div>
      }

      {showCategories &&
        <div className='filter'>
          <label className='filter__label'>Category</label>
          {filteredActivities?.length === 0 && searchTerm && (
            <p className='filter__no-results-message'>No category results for "{searchTerm}"</p>
          )}
          <Suspense fallback={<div>Loading</div>}>
            <SliderPicker
              extractItemKey={({ id }) => id}
              items={filteredActivities}
              onPick={handleActivityPick}
              renderItem={(activity) => (
                <button
                  className={`button secondary filter__option ${buttonSize} ${isActivityPicked(activity.slug) ? 'filter__option--picked' : ''}`}
                  type='button'>
                  { activity.details.noun
                    ? activity.details.noun
                    : activity.name }
                </button>
              )}
            />
          </Suspense>

        </div>
      }
      {showVibes &&
        <div className='filter'>
          <label className='filter__label'>{vibeLabel}</label>
          {filteredVibes?.length === 0 && searchTerm && (
            <p className='filter__no-results-message'>No vibe results for "{searchTerm}".</p>
          )}
          <Suspense fallback={<div>Loading</div>}>
            <SliderPicker
              items={filteredVibes}
              onPick={handleVibePick}
              renderItem={(vibe) => (
                <button
                  className={`button secondary filter__option ${buttonSize} ${isVibePicked(vibe.slug) ? 'filter__option--picked' : ''}`}
                  type='button'
                >
                  {vibe.name}
                </button>
              )}
            />
          </Suspense>

        </div>
      }
    </>
  )
}

VibemapSearchFilterPicker.propTypes = {
  doFilterSearch: PropTypes.bool,
}

VibemapSearchFilterPicker.defaultProps = {
  doFilterSearch: false,
  buttonSize: 'base',
  showCategories: true,
  showCities: true,
  showVibes: true,
  vibeLabel: `Vibes`,
  vibeOrder: `popularity`
}

export default VibemapSearchFilterPicker