import React, { useEffect, useState } from 'react'

import { RRule } from 'rrule'

import Grid from '@mui/material/Grid'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { FormGroup, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'

import InputLabel from '@mui/material/InputLabel'

import Button from '@mui/material/Button'
import DatePicker from 'components/forms/datePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import TextField from '@mui/material/TextField'
import { toTitleCase } from 'components/utils/textUtils.js'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { useRecurringDates } from './hooks.recurringDates.js'
import {
  DAYS,
  DAYS_OF_MONTH,
  INTERVAL_LABELS,
  MONTHS,
  POSITIONAL,
  POSITIONAL_LABELS
 } from './constants.recurringDates.js'

const RecurringDates = ({
  defaultRule = 'DTSTART:20120201T093000Z\nRRULE:FREQ=WEEKLY;BYDAY=2SA',
  onChange,
  ...props
}) => {

  const {
    rule,
    ruleSet,
    ruleString,
    datesAdditional,
    datesExcluded,
    endDate,
    frequencies,
    interval,
    byDayLabel,
    positionOrdinalSet,
    // Actions
    addNewDate,
    setDatesAdditional,
    setDatesExcluded,
    setPositionSelected,
    updateDatesAdditional,
    updateEndDate,
    updateRule,
    updateDays,
    updateDaysOfMonth,
  } = useRecurringDates({ defaultRule, onChange })


  const selectStyle = { minWidth: '120px' }
  const inlineFieldStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  }

  // Frequeny i.e. "Monthly", "Weekly", etc.
  const freq = rule?.options?.freq
  const frequencyLabel = frequencies[freq]?.toLowerCase()

  const referenceOrder = ["MONTHLY", "WEEKLY", "YEARLY", "DAILY", "HOURLY", "MINUTELY", "SECONDLY"];
  const frequenciesSorted = frequencies.sort((a, b) => {
    return referenceOrder.indexOf(a) - frequencies.indexOf(b);
  });

  const frequencyOptions = frequenciesSorted.map(frequency => ({
    value: RRule[frequency],
    label: toTitleCase(frequency)
  }))

  const FrequencyPicker = () => {
   return (
     <FormControl>
        <Select
          value={freq}
          label="Frequency"
          onChange={(e) => updateRule({ freq: e.target.value })}
          size="small"
          style={selectStyle}
          inputProps={{
            name: 'frequency',
            id: 'frequency',
            label: 'Repeat',
          }} >
          {frequencyOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
   )
  }


  // Make Mui text for interval
  const IntervalInput = () => {
    return (
      <FormControl style={inlineFieldStyle}>
        <FormLabel style={{ marginLeft: 10 }}>Every</FormLabel>
        <TextField
          id="interval"
          type="number"
          value={interval}
          onChange={(e) => updateRule({ interval: parseInt(e.target.value) })}
          InputLabelProps={{ shrink: true }}
          style={{ width: '6rem' }}
        />
        <FormLabel>
          {INTERVAL_LABELS[freq]}
        </FormLabel>
      </FormControl>

    )
  }

  // Make Mui fields for by day, month, week, etc.
  const dayOptions = DAYS.map((day) => ({ value: day, label: day }))

  const DayButtons = ({ disabled = false }) => {
    return (
      <ToggleButtonGroup
        value={byDayLabel}
        onChange={(e) => updateDays(e.target.value)}
        aria-label="text formatting"
      >
        {dayOptions.map((option) => (
          <ToggleButton key={option.value} value={option.value} aria-label={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )
  }

  const monthOptions = MONTHS.map((month, index) => ({ value: index + 1, label: month }))

  const MonthButtons = () => {
    return (
      <ToggleButtonGroup
        value={rule?.options?.bymonth} // TODO: does an array of months work?
        onChange={(e) => updateRule({ bymonth: parseInt(e.target.value) })}
        aria-label="text formatting" >
        {monthOptions.map((option) => (
          <ToggleButton key={option.value} value={option.value} aria-label={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )
  }


  // Grid of days of month
  const daysOfMonthOptions = DAYS_OF_MONTH.map((day, index) => ({ value: index, label: day }))
  const dayOfMonthCurrent = rule?.options?.bymonthday.map(day => DAYS_OF_MONTH.indexOf(day))
  const DaysOfMonthButtons = () => {
    return (
      <ToggleButtonGroup
        value={dayOfMonthCurrent !== -1 ? dayOfMonthCurrent : null}
        onChange={(e) => updateRule({ bymonthday: DAYS_OF_MONTH[parseInt(e.target.value)]})}
        aria-label="text formatting"
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)' }}
      >
        {daysOfMonthOptions.map((option) => (
            <ToggleButton
              key={option.value}
              value={option.value}
              aria-label={option.label}
              sx={{ display: 'inline-table'}}
              >
              {option.label}
            </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )
  }


  const [positionalBy, setPositionalBy] = React.useState('ordinal')
  useEffect(() => {
    if (positionOrdinalSet) {
      setPositionalBy('ordinal')
    }
  }, [positionOrdinalSet])

  const handlePositionalBy = (event) => {
    const value = event.target.value
    setPositionalBy(value)
    if (value === 'ordinal') {
      updateRule({ bysetpos: 1 })
    }
  }


  if (positionOrdinalSet) console.log('DEBUG: positionOrdinalSet ', positionOrdinalSet)
  const positionOptions = POSITIONAL.map((pos, index) => ({
    value: pos,
    label: POSITIONAL_LABELS[index]
  }))


  const PositionSelect = ({ disabled = false}) => {
    return (
      <FormControl style={inlineFieldStyle}>
        <InputLabel>On the</InputLabel>
        <Select
          defaultValue={positionOptions[0]?.value}
          disabled={disabled}
          value={positionOrdinalSet}
          size="small"
          style={selectStyle}
          onChange={(e) => updateRule({ bysetpos: e.target.value })}>
          {positionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const MonthIntervals = () => {
    return (
      <div>
        <h4>Month Intervals</h4>
        <p>Add month intervals</p>
      </div>
    )
  }

  const gridStyle = { padding: '1rem', marginBottom: '3rem' }

  return (
    <Grid style={gridStyle}>

      <FormGroup>
        <Grid>
          <FormLabel>Repeats</FormLabel>
          <FrequencyPicker />
          <IntervalInput />
        </Grid>

      </FormGroup>

      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">By Day</FormLabel>
        {frequencyLabel === 'monthly' || frequencyLabel === 'yearly'
          ?
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="day"
            name="radio-buttons-group"
            value={positionalBy}
            onChange={handlePositionalBy}>

            <FormControlLabel value="ordinal" control={<Radio />} label="On the 1st, 2nd, etc" />
            <FormGroup>
              <Grid>
                <PositionSelect disabled={positionalBy !== 'ordinal'} />
                <DayButtons disabled={positionalBy !== 'ordinal'} />
              </Grid>
            </FormGroup>

            <FormControlLabel value="unit-of-time" control={<Radio />} label="Each date" />
            <FormGroup disabled={positionalBy !== 'unit-of-time'} >
              <FormLabel> On the </FormLabel>
              {frequencyLabel === 'weekly'
                ? <DayButtons />
                : frequencyLabel === 'yearly'
                  ? <MonthButtons />
                  : frequencyLabel === 'monthly'
                    ? <DaysOfMonthButtons />
                    : null
              }
            </FormGroup>

          </RadioGroup>
          : frequencyLabel === 'weekly'
            ? <DayButtons />
            : null
        }
      </FormControl>

      {
        frequencyLabel === 'monthly'
          ? <MonthIntervals />
          : null
      }

      <FormGroup style={{ maxWidth: '400px', marginTop: '1rem' }}>
        <FormLabel>Additional dates</FormLabel>
        {datesAdditional.map((date, index) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              key={date}
              value={date}
              onChange={(value) => updateDatesAdditional(value, index)}
            />
          </LocalizationProvider>
        ))}
        <Button className='button ui secondary' size="small" variant="contained" onClick={() => addNewDate()}>Add date</Button>
      </FormGroup>

      <FormGroup style={{ marginTop: '1rem' }}>
        <FormLabel>End date (repeat until)</FormLabel>
        <DatePicker
          key={'until'}
          value={endDate}
          onChange={(value) => updateEndDate(value, index)}
          style={{ maxWidth: '200px' }}
        />
      </FormGroup>

    </Grid>
  )
}

export default RecurringDates