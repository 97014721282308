import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs'
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'
import {
    DAYS,
    DAYS_OF_MONTH,
    MONTHS,
    POSITIONAL, POSITIONAL_LABELS
} from './constants.recurringDates.js'


// Handles rrules like this: RRULE:FREQ=MONTHLY;BYDAY=2SA
// Standard is here: https://www.rfc-editor.org/rfc/rfc2445
// FREQ is the only required input and refers to how often the event occurs
// FREQ is one of "SECONDLY" / "MINUTELY" / "HOURLY" / "DAILY" / "WEEKLY" / "MONTHLY" / "YEARLY"

// BYDAY is the ordinal day the event occurs, e.g. 2nd Saturday is "2SA"
// BYDAY is one of "MO" / "TU" / "WE" / "TH" / "FR" / "SA" / "SU"

// BYMONTH is which month the event occurs
// BYMONTH is 1-12
// BYMONTHDAY is 1-31
// BYYEARDAY is 1-366
//
// COUNT is the total occurrences of the event
// INTERVAL is the time between recurring events

// RRule.js constructor options with clean defaults
export const getDefaultRule = (ruleString) => {
    try {
        // Step 1: Create RRule instance from string or default
        let rule = ruleString ? rrulestr(ruleString) : new RRule()

        //let rule = ruleString ? RRule.fromString(ruleString) : new RRule()
        // Step 2: Extract options from the rule
        let options = rule.origOptions

        // Step 3: Modify options with defaults or new values
        //options.freq = options.freq || RRule.WEEKLY
        //options.interval = 1


        // Django formats RRULE:FREQ=MONTHLY;INTERVAL=1;BYSETPOS=3;BYDAY=WE
        // As RRULE:FREQ=MONTHLY;BYDAY=+3WE
        const bynweekday = Array.isArray(rule?.options?.bynweekday) ? rule.options.bynweekday[0] : undefined
        // e.g. [[0, 1]] for the Monday, 1st of the month
        const byday = bynweekday && bynweekday.length > 0 && bynweekday[0]
        const ordinal = bynweekday && bynweekday.length > 0 && bynweekday[1]

        options.freq = 1

        options.byweekday = rule.options.byweekday || DAYS[byday]
        options.bysetpos = rule.options.bysetpos || ordinal
        options.bymonthday = rule.options.bymonthday

        //options.bymonth = []
        options.bymonthday = []
        options.byhour = []
        options.byminute = []
        options.bysecond = []
        options.until = null

        //if (ruleString) debugger;

        // Step 4: Create a new RRule instance with modified options
        const ruleClean = new RRule(options)
        return ruleClean
    } catch (e) {
        console.log('DEBUG getDefaultRule error ', e);
        return new RRule()
    }
}

export const getDefaultRuleSet = (ruleString) => {
    let ruleSet = ruleString
        ? RRuleSet.fromString(ruleString)
        : new RRuleSet()

        // Step 2: Extract options from the rule
    let options = ruleSet.origOptions

    // Step 3: Modify options with defaults or new values
    //options.freq = options.freq || RRule.WEEKLY
    //options.interval = 1
    //options.bymonth = []
    options.bymonthday = []
    options.byhour = []
    options.byminute = []
    options.bysecond = []

    const ruleSetClean = new RRuleSet(options)
    return ruleSetClean
}

export const getRdates = (ruleString) => {

    // Parse a rule string for RDATEs like this
    /*
    RDATE:20241205T080000Z
    RDATE: 20241206T080000Z
    RDATE: 20241207T080000Z
    */

    // Split the string into an array of dates
    const rDates = ruleString.split('\n').map(date => date.replace('RDATE:', ''))
    // Convert the string dates to Date objects
    const dates = rDates.map(date => {
        const dateClean = date.replace(' ', '')
        const formattedDate = `${dateClean.slice(0, 4)}-${dateClean.slice(4, 6)}-${dateClean.slice(6, 8)}T${dateClean.slice(9, 11)}:${dateClean.slice(11, 13)}:${dateClean.slice(13, 15)}Z`;

        const newDate = dayjs(formattedDate)
        return newDate
    })

    return dates
}


// Create a new rule set with a 1st rule and a list of dates
export const createRuleSet = (rule, dates) => {
    const ruleSet = getDefaultRuleSet()
    ruleSet.rrule(new RRule(rule.options))
    dates.map(date => ruleSet.rdate(date.toDate()))

    return ruleSet
}


export const useRecurringDates = ({
    defaultRule = 'RRULE:FREQ=WEEKLY;', // like this: DTSTART:20120201T093000Z\nRRULE:FREQ=WEEKLY;BYDAY=2SA
    onChange = () => { }
}) => {

    // RRule object; for now just one rule for the entire set
    const [rule, setRule] = useState(getDefaultRule())
    const [ruleSet, setRuleSet] = useState(getDefaultRuleSet())

    // Check if rule has been updated from the default
    const [ruleChanged, setRuleChanged] = useState(false)

    useEffect(() => {
        if (defaultRule && defaultRule.includes('RRULE')) {
            try {
                setRule(getDefaultRule(defaultRule));
                setRuleSet(getDefaultRuleSet(defaultRule))
            } catch (error) {
                console.error('Error parsing defaultRule ', error);
            }
        }

        if (defaultRule && defaultRule.includes('RDATE')) {
            const dates = getRdates(defaultRule)
            const datesValid = dates.filter(date => dayjs(date).isValid())
            setDatesAdditional(datesValid)
            datesValid.forEach(date => {
                ruleSet.rdate(datetime(date.year(), date.month() + 1, date.date(), date.hour(), date.minute()));
            });

        }
    }, [defaultRule])

    // The string representation of the rule that is already serialized for a POST/PATCH
    const ruleString = useMemo(() => rule?.toString(), [rule]);
    const ruleSetString = useMemo(() => ruleSet?.toString(), [ruleSet]);
    console.log('DEBUG rule default ', defaultRule, ruleSet.rdates());

    const updateRule = (newProperties) => {
        const optionsExisting = rule?.options || {
            dtstart: null,
            freq: RRule.WEEKLY
        }

        // TODO: if freq changes, reset the byweekday and bysetpos
        // TODO: if byweekday changes, reset the bysetpos


        const optionsNew = {
            ...optionsExisting,
            ...newProperties
        }

        const updatedRule = new RRule(optionsNew)
        console.log('DEBUG updateRule ', updatedRule?.options);

        // Has changed
        if (updatedRule.toString() !== ruleString) {
            setRule(updatedRule)
            //ruleSet.rrules().map(rule => ruleSet.exrule(rule))
            setRuleSet(createRuleSet(updatedRule, datesAdditional))
            setRuleChanged(true)
        }
    }

    // Update the rule string when the rule object changes
    useEffect(() => {
        console.log('DEBUG rule ', rule?.toString(), ruleString, ruleSetString);
        rule && onChange?.(ruleSetString, ruleChanged)
    }, [ruleSetString])


    // Start and end dates
    const [startDate, setStartDate] = useState(rule?.options?.dtstart)
    const [endDate, setEndDate] = useState(rule?.options?.until)


    // Individual dates can also be added to the rule
    const [datesAdditional, setDatesAdditional] = useState([])
    const [datesExcluded, setDatesExcluded] = useState([])


    // List of individual date occurences
    const updateDatesAdditional = useCallback((value, index) => {
        setDatesAdditional(currentDates => currentDates.map((date, i) => i === index ? value : date));
    }, []);


    // Update the end date or the last date of the rule
    const updateEndDate = useCallback((value) => {
        setEndDate(value)
    }, []);


    const addNewDate = useCallback(() => {
        const newDate = dayjs(); // Default to today's date
        setDatesAdditional(currentDates => [...currentDates, newDate]);
    }, []);

    useEffect(() => {
        if (datesAdditional.length > 0) {
            // Update the ruleset with the new dates
            setRuleSet(createRuleSet(rule, datesAdditional))
        }
    }, [datesAdditional])


    // Frequency, e.g. monthly
    const frequencies = RRule.FREQUENCIES
    const frequency = rule?.options?.freq
    //console.log('DEBUG frequency ', ruleString, frequency, rule?.options);
    // Interval, e.g. every 2 weeks


    // By weekday, e.g. every Monday
    const byDayLabel = DAYS[rule?.options.byweekday] // i.e. MO
    const updateDays = (value) => {
        // Update the rule
        updateRule({ byweekday: DAYS.indexOf(value) })
    }


    const daysOfMonthSelected = DAYS_OF_MONTH[rule?.options?.bymonthday]
    const updateDaysOfMonth = (value) => {
        const day = parseInt(value)
        updateRule({ bymonthday: day })
    }


    // Positional intervals are used for things like "the second Tuesday of the month"
    const positionOrdinalSet = rule?.options?.bysetpos

    // Ordinal position of the day in the month, e.g. the 2nd Tuesday
    const updatePosition = (value) => {
        // Parse as int and update the rule
        const position = parseInt(value)
        updateRule({ bysetpos: position })
    }


    return {
        rule,
        ruleChanged,
        ruleString: rule?.toString(),
        frequencies,
        frequency,
        interval: rule?.options?.interval || 1,
        datesAdditional,
        datesExcluded,
        byDayLabel,
        daysOfMonthSelected,
        positionOrdinalSet,

        // Actions
        setRule,
        addNewDate,
        updateRule,
        updateDays,
        updateDaysOfMonth,
        //updatePosition,
        //updatePositionalInterval,
        updateRule,
    };
};