import React, { useState, useEffect } from 'react'
import useVibemapSearch from '../utils/search-context/hook'
import { useTemplateContext } from 'components/post/TemplateProvider'

import { getVibes } from 'vibemap-constants/dist/vibes.js'
const allVibes = getVibes('all')

import FilterChip from './FilterChip'
import './styles/FilterChips.scss'

function VibemapSearchFilterChips(props) {
  const { vibes, vibeOrder, singleRow, onChange } = props
  const { filters, actions: { setSearchVibes, setSearchCities, setSearchCategory } } = useVibemapSearch()

  const templateContext = useTemplateContext()
  const {
    activityCategories,
    cities
  } = templateContext

  // Set context with vibes from props
  useEffect(() => {
    if (vibes && vibes.length > 0) {
      const updated_vibes = vibes.join(',')
      setSearchVibes(updated_vibes)
    }
  }, [vibes])

  // Call back for outside context
  useEffect(() => {
    const vibes = filters?.vibes
      ? filters.vibes.split(',').filter(str => str !== '')
      : []
    onChange(vibes)
  }, [filters.vibes])

  const filterVibeSlugs = filters.vibes ? filters.vibes.split(',') : []

  const filterCities = cities?.filter(({ id }) => (
    filters.cities.some((city) => city.id === id)
  ))

  const filterVibes = vibeOrder == 'list'
    ? filterVibeSlugs.flatMap(slug => {
      const foundVibe = allVibes.find(vibe => vibe.slug === slug)
      return foundVibe
    })
    : allVibes.filter(({ slug }) => filterVibeSlugs.includes(slug))

  const filterCategory = activityCategories?.find(({ slug }) => (
    filters.category === slug
  ))

  return (
    <div className={`filter-chips ${singleRow ? 'single-row' : 'multi-row'}`}>
      {filterCities?.map((city) => (
        <FilterChip id={city.id} key={city.id} type='city' text={city.name} />
      ))}
      {filterCategory && filterCategory.slug !== 'all' && (
        <FilterChip id={filterCategory} type='category' text={filterCategory.details.noun} />
      )}
      {filters.vibes && filterVibes?.map((vibe) => vibe && vibe.slug
        ? <FilterChip id={vibe.slug} type='vibe' key={vibe.slug} text={vibe.name} />
        : null
      )}
    </div>
  )
}

VibemapSearchFilterChips.defaultProps = {
  singleRow: true,
  vibeOrder: 'list',
  onChange: () => { }
}

export default VibemapSearchFilterChips
