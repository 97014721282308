export const DAYS = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

export const INTERVAL_LABELS = ['Year', 'Month', 'Week', 'Day', 'Hour', 'Minute', 'Second']

export const FREQUENCIES = ["YEARLY", "MONTHLY", "WEEKLY", "DAILY", "HOURLY", "MINUTELY", "SECONDLY"]

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] // 1-12

export const POSITIONAL = ['1', '2', '3', '4', '-1', '-2', '-3', '-4']

export const POSITIONAL_LABELS = ['First', 'Second', 'Third', 'Fourth', 'Last', 'Second to Last', 'Third to Last', 'Fourth to Last']

export const DAYS_OF_MONTH = [...Array(31).keys()].map(i => i + 1) // 1-31

