import React, { useCallback, useRef, useEffect, useReducer, useState, Suspense } from "react"
import { navigate } from "gatsby"
import { pure } from 'recompose'
import isEqual from 'react-fast-compare'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { reducerDeepCompare } from 'components/utils/useStateDeepEqual'

import { useTemplateContext } from 'components/post/TemplateProvider'
import { dataStore, filterStore, pageStore } from "state/store-zustand";

import axios from 'axios'
import useActionsAPI from 'components/utils/actionsAPI'
import { sendEmail } from 'components/utils/sendEmail'
import { fetchPlacesDetails } from "components/utils/testHelpers.js"

import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api'
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer'
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';

import componentTypes from '@data-driven-forms/react-form-renderer/component-types'
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types'
import FieldArray from '@data-driven-forms/mui-component-mapper/field-array'
import Select from '@data-driven-forms/mui-component-mapper/select'
import TextArea from '@data-driven-forms/mui-component-mapper/textarea'
import TextField from '@data-driven-forms/mui-component-mapper/text-field'

import TextFieldURL from './textFieldURL'
import VibePoints from 'components/elements/vibePoints'
import Icon from 'components/post/icon'

const Map = React.lazy(() => import('components/map/basicMap'))
const UploadPreview = React.lazy(() => import('components/forms/addFile'))

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import { useReward } from 'react-rewards'

import VibemapSearchProvider from 'components/utils/search-context/Provider'
import VibemapSearchField from 'components/search/Field'
import VibemapSearchFilterChips from 'components/search/FilterChips'
import VibemapSearchFilterPicker from 'components/search/FilterPicker'
import VibemapSearchParameterPersister from 'components/search/ParameterPersister'

import { getRelatedVibes } from 'vibemap-constants/dist/vibes.js'
import { uploadImageKit } from 'vibemap-constants/dist/helpers.js'

import './form.scss'

const AddPlace = ({
  updateOnly = false,
  showButtons = true,
  showSearch = true,
  submitPressed = false,
  inDialog = false,
  isLoggedIn = false,
  onCancel = null,
  doSearch = true,
  maxWidth = '1536px',
  themeName = 'vibemap',
  ...props
}) => {

  const templateContext = useTemplateContext()
  const {
    activityCategories,
    currentLocation,
    topLevelCategories
  } = templateContext

  const { location, onSubmit } = props

  const subCategories = activityCategories?.filter(category => {
    const level = parseInt(category.level)
    //console.log(`Filter category to level 0 or 1: `, category.slug, level)
    if (level >= 2) {
      return true
    }
  })

  // Place and Form State
  const addNewPlace = dataStore(state => state.addNewPlace)
  const placeCurrent = dataStore(state => state.placeCurrent)
  const placeID = dataStore(state => state.placeID)
  const setAddNewPlace = dataStore((state) => state.setAddNewPlace)
  const setPlaceCurrent = dataStore((state) => state.setPlaceCurrent)

  const tagsCurrent = filterStore((state) => state.tagsCurrent)

  const theme = pageStore((state) => state.theme)
  const themeObject = pageStore((state) => state.themeObject)
  const businessForm = themeObject?.businessForm
  const offer_icon = businessForm?.offer_icon
  const offer_message = businessForm?.offer_message ? businessForm.offer_message : 'Add a special offer or promotion.'
  const offer_fields = businessForm?.offer_fields ? businessForm.offer_fields : []

  // Get place
  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      console.log('fetchData ', id, id);
      const details = await fetchPlacesDetails(id, undefined, true)
        .catch(e => {
          console.log(`Error with records `, id, e)
        })

      if (details) {
        setPlaceCurrent(details.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }

    if (placeCurrent == null && placeID) {
      fetchData(placeID)
    }

    return () => {
      null
    }
  }, [placeID])


  // Page State
  const [buttonText, setButtonText] = useState('Add New Place')
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [hasError, setHasError] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('Everything is fine.')

  // Form State
  const [formType, setFormType] = useState('business') // or user, or internal
  const formRef = React.createRef(null)
  const isBrowser = typeof window !== `undefined`
  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [description, setDescription] = useState(null)
  const [telephone, setTelephone] = useState(null)
  const [accounts, setAccounts] = useReducer(reducerDeepCompare, [])
  const [offerFirst, setOfferFirst] = useState(null)
  const [activities, setActivities] = useReducer(reducerDeepCompare, [])
  const [images, setImages] = useReducer(reducerDeepCompare, [])

  const [vibesCombined, setVibesCombined] = useReducer(reducerDeepCompare, [])
  const [vibeScore, setVibeScore] = useState(1)
  const [suggestedVibes, setSuggestedVibes] = useReducer(reducerDeepCompare, [])
  const vibesCombinedDep = JSON.stringify(vibesCombined)
  useEffect(() => {
    if (vibesCombined && vibesCombined.length > 0) {
      const suggested = getRelatedVibes(vibesCombined).filter(vibe => !vibesCombined.includes(vibe))
      if (!isEqual(suggested, vibesCombined)) {
        //console.log('DEBUG: suggestedVibes ', vibesCombined, suggested);
        setSuggestedVibes(suggested)
      }
    }
  }, [vibesCombinedDep])

  const [formData, setFormData] = useState(null)
  const [showMessage, setShowMessage] = React.useState(false)
  const [message, setMessage] = React.useState('Shared and copied to clipboard!')
  const setAndShowMessage = (message) => {
    setShowMessage(true)
    setMessage(message ? message : 'Success!')
  }

  const [mapHeight, setMapHeight] = useState(240)
  const [mapWidth, setMapWidth] = useState(380)
  const [latitude, setLatitude] = useState(32)
  const [longitude, setLongitude] = useState(-122)
  const [zoom, setZoom] = useState(14)
  useDeepCompareEffect(() => {
    // Check form width
    const mapWidthChanged = formRef?.current?.offsetWidth != mapWidth
    if (isBrowser && formRef.current && mapWidthChanged) {
      setMapWidth(formRef.current.offsetWidth)
    }
  }, [formRef])

  //⚡ Handle website analysis
  const [hasBusiness, setHasBusiness] = useState(false)
  const [website, setWebsite] = useState(null)
  //⚡ Set data if place is found
  // TODO: use callback

  const placeDep = JSON.stringify(placeCurrent)
  console.log('DEBUG: placeCurrent ', placeCurrent, placeDep)
  useEffect(() => {
    console.log('DEBUG: AddPlace useEffect placeToAdd ', placeCurrent)
    const place = placeCurrent

    if (place && hasBusiness == false) {
      //const { address, description, telephone, vibes, website, url } = place.properties

      const nameNew = place?.properties?.name
        ? place.properties.name
        : place?.name

      const addressNew = place?.properties?.address
        ? place.properties.address
        : place?.address

      const descriptionNew = place?.properties?.description
        ? place.properties.description
        : place?.description

      const telephoneNew = place?.properties?.telephone
        ? place.properties.telephone
        : place?.telephone

      const newWebsite = place?.properties?.url
        ? place.properties.url
        : place?.url

      const newVibes = place?.properties?.vibes
        ? place.properties.vibes
        : place?.vibes

      const offers = place?.properties?.offers
        ? place.properties.offers
        : place?.offers

      const images = place?.properties?.vibemap_images
        ? place.properties.vibemap_images
        : place?.vibemap_images

      if (images && images.length > 0) {
        //console.log('images ', images);
        setImages(images)
      }

      if (nameNew != name) setName(nameNew)
      if (addressNew != address) setAddress(addressNew)
      if (descriptionNew != description) setDescription(descriptionNew)
      if (telephoneNew != telephone) setTelephone(telephoneNew)
      if (newWebsite != website) setWebsite(newWebsite)
      if (offers) setOfferFirst(offers[0])

      if (place?.properties?.vibes != vibesCombined) setVibesCombined(place?.properties?.vibes)

      if (newVibes && newVibes.length > 0) {
        //setSuggestedVibes(getRelatedVibes(newVibes))
      }

      const newLat = place && place.geometry
        ? place.geometry.coordinates[1]
        : currentLocation
          ? currentLocation.latitude
          : 32

      const newLon = place && place.geometry
        ? place.geometry.coordinates[0]
        : currentLocation
          ? currentLocation.longitude
          : -122

      if (latitude != newLat) {
        setLatitude(newLat)
        setLongitude(newLon)
      }

    }
  }, [placeDep])

  const placeCurrentDep = JSON.stringify(placeCurrent)
  useEffect(() => {
    const place = placeCurrent
    const lat = place && place.geometry
      ? place.geometry.coordinates[1]
      : currentLocation?.latitude

    const lon = place && place.geometry
      ? place.geometry.coordinates[0]
      : currentLocation?.longitude
    setLatitude(lat)
    setLongitude(lon)
  }, [currentLocation, placeCurrentDep])

  console.log('placeCurrent ', placeCurrent);


  useEffect(() => {

    (async () => {
      if (!doSearch) return

      if (website && hasBusiness == false) {
        const url = website.replace(/\/+$/, '')
        //console.log(`TODO: Fetch more info based on website `, url);
        const endpoint = `https://dev.vibemap.com/lookup?domain=${url}`
        setIsLoading(true)
        setAndShowMessage('Searching...')
        const response = await axios.get(endpoint).catch(error => console.log(`Problem with website request`, error))
        const business_info = response && response.data
        setIsLoading(false)
        setShowMessage(false)

        //console.log(`Website response`, endpoint, business_info)

        // Update defaults/placeholders
        if (business_info) {
          setHasBusiness(true)
          const existingVibes = vibesCombined ? vibesCombined : []
          if (business_info.description) setDescription(business_info.description)
          if (business_info.activities) setActivities(business_info.activities)

          if (business_info?.vibes && business_info?.vibes.length > 0) setVibesCombined([...new Set([...business_info.vibes, ...existingVibes])])
          //console.log('TODO: handle categories ', business_info.activities);

          if (business_info.images) {
            //console.log('business_info.images ', business_info.images);
            const vibemapImages = business_info.images.map(image => {
              return {
                ...image,
                filename: image.src.split('/').pop(),
                url: image.src
              }
            })

            setImages(vibemapImages)
          }

          if (business_info.accounts) {
            const accounts = Object.entries(business_info.accounts).map(entry => {
              const [key, value] = entry;
              return { 'label': key, 'value': value }
            })
            setAccounts(accounts)
          }
        }
      }
    })()
  }, [website])

  // Check if values have changed
  // Wrapped with FormSpy to get the values
  const FormTemplateCanReset = (props) => <FormTemplate {...props} />;

  const buttonGroupStyle = {
    marginTop: 8,
    position: inDialog ? 'sticky' : 'fixed',
    left: 0,
    right: 0,
    width: '100%',
    margin: '0 auto',
    maxWidth: inDialog ? maxWidth : '100%', // TODO: Set as shared var
  }

  const FormTemplate = ({ formFields, schema }) => {
    const { handleSubmit, onReset, onCancel, getState } = useFormApi();
    const { submitting, valid, pristine } = getState();

    useEffect(() => {
      if (submitPressed) {
        const e = new Event('submit', { cancelable: true })
        handleSubmit(e, formData)
      }
    }, [submitPressed])


    return (
      <div className={'addPlaceForm'} onSubmit={(e) => handleSubmit(e, formData)}>
        {schema.title}
        {formFields}

        <FormSpy>
          {() => (
            <form key='add-form' id='add-form' onSubmit={e => {
              e.preventDefault()
              handleSubmit(e, formData)
            }}>
              {showButtons
                ? (
                  <div className='buttonGroup' style={buttonGroupStyle}>
                    <VibePoints vibeScore={vibeScore} />

                    <Button variant="outlined" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) => handleSubmit(e, formData)}
                      disabled={submitting || !valid}
                      style={{ marginRight: 8 }}
                      color="primary"
                      variant="contained">
                      Submit
                    </Button>
                  </div>
                )
                : null
              }

            </form>
          )}
        </FormSpy>
      </div>
    )
  }

  const FieldListenerWrapper = () => {
    return <FormSpy subcription={{
      values: false,
      dirty: true,
      pristine: true
    }}>{() => <FieldListener />}</FormSpy>
  }

  //⚡ Listen for form changes and vibe score
  const FieldListener = () => {
    const { getState, change } = useFormApi()
    const formValues = getState().values
    const errors = getState().errors
    //console.log(`DEBUG: Form values changed `, formValues);

    const hasWebsiteError = errors.website && typeof errors.website === 'string' || false

    if (formValues?.website != website && !hasWebsiteError) {
      setWebsite(formValues.website)
    }

    if (formValues?.name != name) {
      //setName(formValues.name)
    }

    if (formValues.accounts && formValues.accounts.length > 0) {
      const new_accounts = accounts.map(account => {
        const label = account.label
        formValues.accounts.map(new_account => {
          if (typeof (new_account) == 'string') {
            if (new_account && new_account.includes(label)) {
              account.value = new_account
            }
          }
        })
        return account
      })

      if (!isEqual(accounts, new_accounts)) {
        setAccounts(new_accounts)
      }
    }

    if (formValues.offer_description != offerFirst?.description) {
      setOfferFirst({
        ...offerFirst,
        description: formValues.offer_description
      })
    }

    const accountsScore = formValues.accounts.length * 10
    const vibeScore = vibesCombined ? vibesCombined.length * 10 : 0
    const total = accountsScore + vibeScore
    if (total !== vibeScore) setVibeScore(total)

    return null;
  }

  const FieldGroup = (props) => {
    const { renderForm } = useFormApi()
    const { fields, description, title } = props

    return (
      <Paper
        className="fieldGroup"
        variant="outlined"
        sx={{ p: 2, marginTop: `2rem`, width: `100%` }}>
        <h4>{title}</h4>
        <p>{description}</p>
        {fields.map((field, i) =>
          <Grid key={field.name + i} sx={{ marginBottom: `2rem` }}>
            {renderForm([field])}
          </Grid>
        )}
      </Paper>
    )
  }

  const FileUploadComponent = (props) => {
    const { input, meta, label } = useFieldApi(props)

    const testingDomain = `http://localhost:9999`
    const domain = process.env.GATSBY_ACTIVE_ENV == 'staging'
      ? `https://vibemap.com`
      : `https://vibemap.com`

    const uploadFiles = async (files) => {

      console.log(`handleUploadedFiles `, files)
      setImages(files)
    }

    const handleFiles = (imageFiles) => {
      //console.log(`handleFiles `, imageFiles)
      input.onChange(imageFiles)
      setImages(imageFiles)
    }

    return (
      <Suspense fallback={<span>...</span>}>
        <UploadPreview
          images={images}
          onError={handleError}
          onUpload={uploadFiles}
          onChange={handleFiles} />
      </Suspense>
    )
  }

  const mapRef = useRef()
  const MapMarker = (props) => {
    const { input, meta, label } = useFieldApi(props)
    //input.onChange(input.value)
    //console.log('MapMarker ', input, meta, label)

    const handleMarker = (event) => {
      input.value = event.lngLat.toString()
      input.onChange(event.lngLat.toString())
    }

    return (
      <div className={`MuiGrid-root MuiGrid-item`}>
        <Suspense fallback={<div>Loading...</div>}>
          <Map
            mapRef={mapRef}
            latitude={latitude}
            longitude={longitude}
            onChange={handleMarker}
            showExplore={false}
            showPlaces={false}
            showDraggableMarker={true}
            map3d={false}
            height={mapHeight}
            width={mapWidth}
            zoom={zoom} />
        </Suspense>
      </div>
    )
  }

  const handleVibesChanged = (vibes) => {
    //console.log(`handleVibesChanged `, vibes, vibesCombined);
    if (vibes && vibes.length > 0 && !isEqual(vibes, vibesCombined)) {
      const suggested = getRelatedVibes(vibes)
      setVibesCombined(vibes)
      setSuggestedVibes(suggested)
    }
  }

  const VibesPicker = React.memo(
    (props) => {
      const { input, meta, label } = useFieldApi(props)

      // Only update if the values have changed
      if (!isEqual(input.value, vibesCombined)) {
        //console.log(`Vibes changed `, input.value, vibesCombined);
        input.onChange(input.value)
      }

      return (
        <div className={'add-vibes'}>
          <VibemapSearchProvider
            doAutoSearch={true}
            errorCallback={handleError}
            shouldUpdateURL={false}>
            {false &&
              <VibemapSearchParameterPersister />
            }
            <VibemapSearchFilterChips
              singleRow={false}
              vibes={vibesCombined}
              onChange={handleVibesChanged} />
            <VibemapSearchField
              autoComplete
              isPlaceSearch={false}
              placeholder={'Search vibes…'}
              secondarySearch={{ field: 'vibes', value: input.value }} />
            <VibemapSearchFilterPicker
              showCategories={false}
              showCities={false}
              buttonSize={`small`}
              suggestedVibes={suggestedVibes}
              vibeLabel={`Suggested Vibes`}
              vibeOrder={'list'}
              doFilterSearch />
          </VibemapSearchProvider>
        </div>
      )
    })

  const componentMapper = {
    [componentTypes.SELECT]: Select,
    [componentTypes.TEXTAREA]: TextArea,
    [componentTypes.TEXT_FIELD]: TextField,
    [componentTypes.FIELD_ARRAY]: FieldArray,
    'field-listener': FieldListenerWrapper,
    'file-upload': FileUploadComponent,
    'map': MapMarker,
    'sub-form': FieldGroup,
    'text-field-icon': TextFieldURL,
    'vibes': VibesPicker
  }

  const coords = useCallback(() => {
    return `${longitude},${latitude}`
  }, [longitude, latitude])

  const schema = {
    fields: [
      {
        "component": "sub-form",
        "title": "About",
        "description": "Tell us about the location.",
        "name": "subform-about",
        "fields": [
          {
            name: 'name',
            label: 'Name',
            component: componentTypes.TEXT_FIELD,
            isRequired: true,
            validate: [{ type: validatorTypes.REQUIRED }],
            initialValue: name
          },
          {
            name: 'description',
            label: 'Description',
            component: componentTypes.TEXTAREA,
            initialValue: description
          },
          {
            "component": componentTypes.SELECT,
            "label": "Pick Sub-Categories",
            "name": "select",
            "isMulti": true,
            "simpleValue": true,
            "isClearable": true,
            "isSearchable": true,
            initialValue: activities,
            "options": subCategories?.map(category => {
              return {
                label: category.name.replace('&amp;', '&'),
                value: category.slug
              }
            })
          },
          {
            name: 'address',
            label: 'Address',
            component: componentTypes.TEXT_FIELD,
            isRequired: true,
            validate: [{ type: validatorTypes.REQUIRED }],
            initialValue: address
          },
          /*
          {
            name: 'map',
            label: 'Map',
            type: 'map',
            component: 'map',
            initialValue: coords
          },
          */
          {
            name: 'phone',
            label: 'Phone',
            component: componentTypes.TEXT_FIELD,
            initialValue: telephone
          },
          {
            name: 'website',
            label: 'Website',
            component: componentTypes.TEXT_FIELD,
            helperText: 'type some address like: https://www.feedingamerica.org/',
            initialValue: website,
            validate: [{
              type: validatorTypes.URL,
            }],
          },
          /*
          {
            "component": componentTypes.SELECT,
            "label": "Place Category",
            "name": "select",
            "isMulti": true,
            "simpleValue": true,
            "isClearable": true,
            "isSearchable": true,
            initialValue: [],
            "options": topLevelCategories.map(category => {
              return {
                label: category.name.replace('&amp;', '&'),
                value: category.slug
              }
            })
          },
          */
        ]
      },
      {
        "component": "sub-form",
        "title": "Offers",
        "description": offer_message,
        "name": "subform-offers",
        "fields": [
          {
            component: componentTypes.TEXT_FIELD,
            name: 'offer_title',
            label: 'Title',
            initialValue: offerFirst?.name,
            hideField: offer_fields.includes('title') ? false : true
          },
          {
            component: componentTypes.TEXTAREA,
            name: 'offer_description',
            label: 'Description',
            initialValue: offerFirst?.description
          },
          {
            component: componentTypes.TEXT_FIELD,
            name: 'offer_link',
            label: 'Link',
            hideField: offer_fields.includes('link') ? false : true,
          },
          {
            component: componentTypes.TEXT_FIELD,
            name: 'offer_code',
            label: 'Code',
            hideField: true
          },
          {
            component: componentTypes.TEXT_FIELD,
            name: 'offer_hours',
            label: 'Hours',
            hideField: true,
            initialValue: offerFirst?.hours_display
          }
        ]
      },
      {
        "component": "sub-form",
        "title": "Connect",
        "description": "Social accounts and ways to connect with this location.",
        "name": "subform-accounts",
        "fields": [
          {
            component: componentTypes.FIELD_ARRAY,
            name: 'accounts',
            label: 'Add account links (undo / redo)',
            initialValue: accounts.filter(account => account?.value && account.value !== null),
            fields: [
              {
                component: 'text-field-icon',
                label: 'hyperlink'
              },
            ],
          }
        ]
      },
      {
        "component": "sub-form",
        "title": "The Vibe",
        "description": "Tell us about the vibe. Vibemap discovered the following vibes. Add more below",
        "name": "subform-vibe",
        "fields": [
          {
            name: 'vibes',
            label: 'Vibes',
            type: 'vibes',
            component: 'vibes'
          },
          {
            component: 'file-upload',
            label: 'File upload',
            name: 'file-upload-field-name',
            type: 'file'
          }
        ]
      },
      {
        component: 'field-listener',
        name: 'listener',
        hideField: true,
      },
    ],
  }

  const showForm = placeCurrent !== null || addNewPlace
  const { reward: balloonsReward, isAnimating: isBalloonsAnimating } = useReward(
    'balloonsReward',
    'balloons',
    {
      elementCount: 20,
      position: 'fixed',
      colors: ['#F72585', '#7209B7', '#3A0CA3', '#4361EE', '#4CC9F0'],
      zIndex: 10,
    }
  )

  // API Actions for Form
  const { patchPlace } = useActionsAPI()

  //⚡ Submit to Django
  const handleSubmit = async (data, form) => {
    console.log('handleSubmit ', data, form)
    //console.log('Get form state ', form.getState())
    const instagram = accounts.find(account => account.label === 'instagram')?.value
    const facebook = accounts.find(account => account.label === 'facebook')?.value
    const twitter = accounts.find(account => account.label === 'twitter')?.value

    const point = data?.map?.split
      ? data.map.split(",").map(Number)
      : [longitude, latitude]

    // Cloud Dev: 143.198.100.9
    // Local Dev: 127.0.0.1:8001
    const prod_mode = true
    const domain = prod_mode
      ? `https://api.vibemap.com`
      : `http://localhost:9000`

    const add_place_endpoint = `${domain}/v0.3/places/`

    // TODO: add categories, images
    //console.log(`Vibes in form `, data.vibes);
    //console.log(`Handle submit images `, images)

    const place_data = {
      ...data,
      "categories": data.select,
      "subcategories": data.select,
      "tags": tagsCurrent ? tagsCurrent : [],
      "vibes": vibesCombined ? vibesCombined : [],
      "images": images,
      "facebook": facebook ? facebook : null,
      "instagram": instagram ? instagram : null,
      "twitter": twitter ? twitter : null,
      "telephone": data.phone,
      "point": point,
      "url": data.website,
      "offers": [
        {
          "name": data.offer_title,
          "description": data.offer_description,
          "link": data.offer_link,
          "code": data.offer_code,
          "hours_display": data.offer_hours
        },
        ...data?.offers ? data.offers : []
      ],
      // Include blank
      "neighborhood": "",
      "short_description": "",
      "timezone": "",
      "hours": "",
      "data_sources": [],
      "is_closed": false,
      "reviews": data?.reviews || [],
      "other_vibes": [],
      "opening_hours": [],
      "price": data?.price || null,
      "ratings": [],
      "stats": [],
      "tips": [],
      "attributes": [],
      "communities": [],
      "editorial_categories": [],
      "hotspots_events": [],
      "aggregate_rating": 4,
      "foursquare_id": "",
      "code": ""
    }

    setAndShowMessage('Adding place, vibes, and photos. This might take a minute...')

    if (isLoggedIn == false && updateOnly == true) {
      setAndShowMessage('Thanks for the suggestion!')
      const message = `New place edits were suggested: `
      sendEmail(message, data)
      balloonsReward()

      return false
    }

    // If place exists, PATCH it
    if (updateOnly == true) {
      // TODO: Make this a page param
      const dev_mode = false
      const response = await patchPlace(placeCurrent.id, place_data, themeName, dev_mode)

      if (response && (response.status == 200 || response.status == 201) && response.data) {
        sendEmail(message, data)
        setAndShowMessage('Place was updated!')
        balloonsReward()
      }
      else {
        setAndShowMessage('There was a problem updating this event. Please try again.')
      }
      return true
    }

    if (updateOnly == false) {
      const response = await axios({
        method: 'post',
        url: add_place_endpoint,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(place_data)
      }).catch(e => {
        console.log('Problem with request ', e);
        setAndShowMessage('There was a problem with this place. Please try again.')
      })

      if (response && (response.status == 200 || response.status == 201) && response.data) {
        setAndShowMessage(response.data)
        setButtonText('Add Another')
        setIsSubmitted(true)
        setAddNewPlace(false)
        onSubmit(place_data)
        balloonsReward()
        return true
      } else {
        setAndShowMessage('There was a problem with this place. Please try again.')
        return false
      }
    }

  }

  const handleCancel = () => {
    navigate(-1)
  }

  const handleError = (hasError = true, message) => {
    console.log(`handleError`, hasError, message)
    setHasError(hasError)
    setErrorMessage(message)
  }

  return (
    <div ref={formRef}>

      <span
        id="balloonsReward"
        style={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          zIndex: 10,
        }}
      />

      <Snackbar
        autoHideDuration={6000}
        open={showMessage}
        message={message}
        sx={{ bottom: 80 }} />

      {isLoading &&
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
          <Alert variant="outlined" severity="info">
            <p>{`We're searching the web for info about ${name ? name : ' place'}`}</p>
            <LinearProgress color="secondary" />
          </Alert>
        </Stack>
      }

      {isSubmitted || (!showForm && !isSubmitted) &&
        <Paper sx={{ p: `2rem`, fontSize: `1.4rem` }}>
          <p>Look up your business or add a new one.</p>
          <p>
            <button className={`button medium`} onClick={() => setAddNewPlace(true)}>{buttonText}</button>
          </p>

          <p>
            <Icon type='bolt' color='#ff9800' size={50} />
            How it works:
          </p>
          <ul style={{ paddingLeft: `4rem` }}>
            <li>Vibemap searches the web for your place</li>
            <li>We collect about how it looks and feels, aka vibes</li>
            <li>The more you add the more vibe points the place earns</li>
            <li>Vibe points make the place show up stronger</li>
          </ul>

        </Paper>
      }

      {showForm &&
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={FormTemplate}
          schema={schema}
          onSubmit={(values, formApi) => {
            console.log('onSubmit ', values, formApi)
            handleSubmit(values, formApi)
          }}
          onCancel={() => handleCancel()}
          subscription={{ values: true }}
          style={{ paddingTop: '2rem' }}
        />
      }

    </div>
  )
}

//AddPlace.whyDidYouRender = true
export default pure(AddPlace);
