import React from 'react'

import AnimatedNumber from "animated-number-react";
import Icon from 'components/post/icon'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


function VibePoints({ vibeScore }) {
  const formatValue = (value) => value.toFixed(0);

  return (
    <div className='vibePoints'>
      <Icon type='bolt' color='#ff9800' size={50} />
      <AnimatedNumber
        className='score'
        value={vibeScore}
        duration={4000}
        formatValue={formatValue} />
      <span> </span>
      <span>Vibe points</span>
      <Tooltip title="Vibe points make your profile show up higher online">
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default VibePoints
