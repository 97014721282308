import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce'

const useSEOSearch = (query, latitude, longitude) => {
    const [isLoading, setIsLoading] = useState(false);

    const searchEvents = async (query, latitude, longitude) => {
        if (!query || query.length < 6) return null;
        setIsLoading(true);

        // Cancel the previous request
        const abortController = new AbortController();

        // Start a new request
        const endpoint = 'https://dev.vibemap.com/search_events';
        const params = new URLSearchParams();
        params.append('query', query);
        if (latitude && longitude) {
            params.append('latitude', latitude);
            params.append('longitude', longitude);
        }

        const response = await axios.get(`${endpoint}?${params.toString()}`, { signal: abortController.signal })
            .catch(function (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.log('axios error ', error.response && error.response.statusText);
                }
                return { data: [], count: 0, query: '?' + query };
            });

        if (response && response.data) {
            const data = response.data;
            const options_new = data.map((option) => {
                return {
                    title: option.name,
                    ...option,
                };
            })
        }
    }


    const searchGoogle = async (query, typeFilter = 'organic') => {
        if (!query || query.length < 6) return null;
        // Cancel the previous request
        const abortController = new AbortController();
        setIsLoading(true);

        // Start a new request
        const endpoint = 'https://dev.vibemap.com/search_google';
        const params = new URLSearchParams();
        params.append('query', query);

        const response = await axios.get(`${endpoint}?${params.toString()}`, { signal: abortController.signal })
            .catch(function (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.log('axios error ', error.response && error.response.statusText);
                }
                return { data: [], count: 0, query: '?' + query };
            });

        if (response && response.data) {
            const data = response.data;
            // Filter to just type = organic
            // Note: events resuls also can be fetched this way and could be useful.
            const filteredData = data.filter((option) => {
                return option.type === typeFilter
            })

            return filteredData;
        }
    }


    const searchResultsToOptions = (data) => {
        return data.map((option) => {
            return {
                name: option.title,
                title: option.title,
                description: option.description,
                link: option.url,
                ...option,
            };
        });
    }


    return {
        searchEvents,
        searchGoogle,
        searchResultsToOptions,
        isLoading };
};

export default useSEOSearch;