export const toTitleCase = (str) => {
  if (typeof str == 'string') {
    str = str.toLowerCase().split(' ')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
  } else {
    return str
  }
}

export const decodeHtmlEntities = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.documentElement.textContent;
}

export const imageURlsToObjects = (event_info) => {
  if (event_info?.image || event_info?.images) {
    const images_found = event_info?.images || event_info?.image;
    const images = Array.isArray(images_found)
      ? images_found.map(image => {
        return {
          src: image,
          url: image
        };
      })
      : [{
        src: event_info.image,
        url: event_info.image
      }];

    const vibemapImages = images.map(image => {
      try {
        const srcURL = typeof (image.src) === 'string'
          ? image.src.split('/').pop()
          : Object.values(image.src)[0];

        return {
          ...image,
          filename: srcURL,
          url: image.src
        };
      }
      catch (error) {
        console.log('Error with image', image, error);
      }
    });

    return vibemapImages;
  }
  return [];
};

