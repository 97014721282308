import React from "react"
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

const today_start = dayjs().hour('18').minute('00').second('00')
const today_end = dayjs().hour('21').minute('00').second('00')

const DatePicker = ({
  type = 'date',
  label = "Start date",
  name,
  value = today_start,
  setValue = () => { },
  isMobile = false,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      {isMobile
        ? (
          <MobileDatePicker
            id="date-picker"
            key={'start-date'}
            label={label}
            inputFormat="MM/DD/YYYY"
            defaultValue={value}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            //onError={handleDateError}
            error={false}
            slotProps={{ id: 'date-picker' }}
            renderInput={(params) => <TextField {...params} />}
          />
        )
        : (
          <DesktopDatePicker
            id="date-picker"
            key={'start-date'}
            label={label}
            inputFormat="MM/DD/YYYY"
            defaultValue={value}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            //onError={handleDateError}
            error={false}
            slotProps={{ id: 'date-picker' }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
    </LocalizationProvider>
  )
}

export default DatePicker