import React, { useEffect, useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'


// Hooks to get data and update global filter state
import useFilterHooks from 'components/filters/useFilterHooks'
const {
    eventTags,
    isEventTag,
    slugify
} = useFilterHooks()


const fetchTags = async (search = null) => {
    const endpoint = "https://api.vibemap.com/v0.3/tags"

    const params = new URLSearchParams()
    if (search) {
        params.append('q', search)
    }

    const response = await fetch(`${endpoint}?${params.toString()}`)
    const data = await response.json()
    const tags = search
        ? data.results.map(tag => tag.name)
        : data.results.filter(tag => isEventTag(tag.name)).map(tag => tag.name)

    return tags
}


export default function TagPicker({
    onChange = () => { },
    defaultValues = [],
    ...props
}) {

    // Fetch tags from API
    const [tags, setTags] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [search, setSearch] = useState(null)

    const defaultOptions = defaultValues.map(tag => {
        return {
            key: slugify(tag),
            label: tag,
            title: tag,
            value: slugify(tag)
        }
    })

    useEffect(() => {
        const fetchTagsAsync = async () => {
            try {
                const tags = await fetchTags()
                console.log('DEBUG tags ', tags)
                setTags(tags)
                setTagOptions(tags.map(tag => {
                    return {
                        key: slugify(tag),
                        label: tag,
                        title: tag,
                        value: slugify(tag)
                    }
                }))
            } catch (error) {
                console.error('Error fetching tags:', error)
            }
        }

        fetchTagsAsync()
    }, [])


    const handleChange = (event, value) => {
        const tags = value.map(tag => tag.title)
        onChange(tags)
    }

    return (
        <div>
            <Stack spacing={3} >
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={tagOptions}
                    getOptionLabel={(option) => option.title}
                    defaultValue={defaultOptions}
                    filterSelectedOptions
                    onChange={(event, value) => {
                        handleChange(event, value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Categories, vibes, tags, etc."
                            placeholder="Add one or more"
                        />
                    )}
                />
            </Stack>
        </div>
    )
}